import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import '../styles/header.css';
import '../styles/index.css';

const Header: React.FC = () => {
  const history = useHistory();

  const handleLogoClick = () => {
    history.push('/');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="logo" onClick={handleLogoClick}>
        <img src="/images/logo.png" alt="Logo" />
      </div>
      <div className="header-content">
        <nav className="desktop-nav">
          <Link to="/product">Products</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/contact">Contact Openuse</Link>
        </nav>
        <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLinkClick={handleLinkClick} />
      </div>
    </header>
  );
};

export default Header;
