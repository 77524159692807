// PrivacyPolicy.tsx
import React from 'react';
import privacyPolicy from '../data/privacyPolicy.json';
import PolicyTemplate, { PolicyData } from '../components/PolicyTemplate';

const PrivacyPolicy: React.FC = () => {
  return <PolicyTemplate policyData={privacyPolicy as PolicyData} />;
};

export default PrivacyPolicy;
