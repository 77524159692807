import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './pages/index';
import { Footer } from './components';
import Header from './components/Header';
import Pricing from './pages/PricingPlan';
import Contact from './pages/Contact'; // Replaced About with Contact
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './styles/index.css';
import Product from './pages/Product';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/product" component={Product} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/contact" component={Contact} /> {/* Replaced About with Contact */}
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;