import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/contact.css';

const API_BASE_URL = process.env.REACT_APP_OPENWEB_API_BASE_URL; // Masked fallback URL

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const url = `${API_BASE_URL}/api/contact`; // API endpoint to send contact form data
    console.log('base URL:', url);
    try {
      const response = await fetch(url, {
        method: 'POST', // HTTP method to use for the request
        headers: {
          'Content-Type': 'application/json', // Content type of the request body
        },
        body: JSON.stringify({ name, email, message }), // Request body containing the form data
      });
      if (response.ok) {
        setSuccessMessage('Message sent successfully!');
        setErrorMessage('');
        setName('');
        setEmail('');
        setMessage('');
        setTimeout(() => {
          history.push('/');
        }, 4000); // Navigate back home after 4 seconds
      } else {
        setErrorMessage('Failed to send message. Please try later.');
      }
    } catch (error) {
      setErrorMessage('Network error. Please check your connection.');
    }
  };

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name">Name:</label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Contact;
