import React from 'react';
import '../styles/policytemplate.css';

interface BulletPoint {
  text?: string;
  bold?: string[];
}

interface SectionBodyItem {
  type?: 'bullet';
  items?: (string | BulletPoint)[];
  heading?: string;
}

interface Section {
  heading: string;
  body: string | (string | SectionBodyItem)[];
}

export interface PolicyData {
  title: string;
  sections: Section[];
}

const PolicyTemplate: React.FC<{ policyData: PolicyData }> = ({ policyData }) => {
  const renderBoldText = (text: string, boldWords: string[]) => {
    const words = text.split(' ');
    return words.map((word, index) => {
      const cleanWord = word.replace(/\*\*/g, ''); // Remove markdown bold markers
      return boldWords.includes(cleanWord) ? <strong key={index}>{cleanWord}</strong> : word;
    }).reduce((prev, curr) => <>{prev} {curr}</>);
  };

  const renderBullet = (item: string | BulletPoint) => {
    if (typeof item === 'string') {
      return <li key={item}>{item}</li>;
    } else {
      return <li key={item.text}>{renderBoldText(item.text || '', item.bold || [])}</li>;
    }
  };

  const renderBody = (body: string | (string | SectionBodyItem)[]) => {
    if (typeof body === 'string') {
      return <p>{body}</p>;
    } else {
      return body.map((item, index) => {
        if (typeof item === 'string') {
          return <p key={index}>{item}</p>;
        } else if (item.type === 'bullet') {
          return (
            <div key={index}>
              {item.heading && <h3>{item.heading}</h3>}
              <ul>
                {item.items?.map((bulletItem) => renderBullet(bulletItem))}
              </ul>
            </div>
          );
        }
        return null; // Handle other types or ignore unknown types
      });
    }
  };

  const renderSections = (sections: Section[]) => {
    return sections.map((section, index) => (
      <div key={index}>
        <h2>{section.heading}</h2>
        {renderBody(section.body)}
      </div>
    ));
  };

  return (
    <div className="policy-template">
      <h1>{policyData.title}</h1>
      {renderSections(policyData.sections)}
    </div>
  );
};

export default PolicyTemplate;
