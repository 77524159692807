import React, { useState } from 'react';
import '../styles/product.css';

// Define the Product interface
interface Product {
  name: string;
  description: string;
  features: string[];
  benefits: string[];
}

const products: Product[] = [
  {
    name: "Open Storage",
    description: "Open Storage is a mobile app designed to revolutionize inventory management. It offers an easy-to-use interface for controlling inventory, ensures data privacy and security by keeping information on the user's device, provides mobile access for managing stock from anywhere, and includes smart analytics for insightful business decisions. This app transforms the tedious task of inventory tracking into a simple, secure, and insightful process.",
    features: ["Effortless Inventory Control: User-friendly interface for adding, editing, and deleting items. Elimination of manual tracking and spreadsheet use", "Elimination of manual tracking and spreadsheet use", "Private and Secure: Data privacy with information stored securely on the user's device. No third-party access, ensuring confidentiality.", "Mobile Convenience: Accessibility of inventory management on-the-go through a mobile app.", "Smart Analytics: Built-in tools for analyzing sales trends and stock levels."],
    benefits: ["Simplification and Streamlining: Reduces the complexity and time spent on inventory management.", "Peace of Mind: Users can manage sensitive data with confidence due to enhanced security measures.", "Flexibility: Allows business operations to continue seamlessly regardless of location, enhancing productivity and control.", "Informed Decision Making: Provides actionable insights that can lead to better business decisions, potentially increasing efficiency and profitability."]
  },
  {
    name: "Open Nosh",
    description: "Open Nosh simplifies and secures your inventory tracking with features like barcode scanning, automatic restocking alerts, and expiry notifications. It offers detailed analytics to help understand usage patterns for better menu planning and cost optimization. Enjoy benefits such as profit maximization by reducing waste, saving time and money through automation, and making informed decisions with data-driven insights, leading to a stress-free inventory management experience.",
    features: ["Scan & Track: Easily scan barcodes or manually add items to track your inventory in real-time.", "Smart Restocking: Get automatic alerts when supplies run low, ensuring you never run out or overstock.", "Expiry Warnings: Stay informed about upcoming expiration dates to prevent food waste and save money.", "Detailed Analytics: Gain valuable insights into your food usage patterns to optimize costs and menu planning.", "Simple & Secure: Enjoy an intuitive interface and peace of mind knowing your data is secure and private."],
    benefits: ["Maximize Profits: Reduce waste and ensure timely restocking to boost your bottom line.", "Save Time & Money: Eliminate manual counting and costly errors with efficient inventory management.", "Make Informed Decisions: Gain data-driven insights to optimize your food purchases and menu planning.", "Stress-Free Inventory: Spend less time worrying about stock levels and more time focusing on what matters most."]
  },
  {
    name: "Open Inspect",
    description: "Open Inspect revolutionizes quality assurance with features like smartphone-based precise measurements, voice-controlled reporting, and automatic report generation. It offers real-time tolerance checks, offline data capture, and historical trend analysis, significantly reducing inspection time, eliminating errors, and enhancing product quality and workflow efficiency.",
    features: ["Effortless Measurement: Capture precise dimensional data using your smartphone camera - no more manual measuring tools or human error.", "Voice-Controlled Reporting: Dictate notes and observations directly into the app, saving you time and improving data accuracy.", "Seamless Data Management: Generate reports automatically and integrate them with your existing Excel workflows for effortless analysis.", "Real-Time Tolerance Checks: Identify out-of-spec parts instantly, preventing defective products from reaching production.", "Offline Functionality: Capture data even without an internet connection, and sync it later for uninterrupted inspections.", "Historical Data Tracking: Analyze trends and identify areas for improvement in your quality control processes."],
    benefits: ["Significantly reduce inspection time.", "Eliminate manual measurement errors.", "Improve data accuracy and reporting efficiency.", "Ensure consistent product quality.", "oost productivity and streamline QA workflows."]
  },
  {
    name: "Open DentAI",
    description: "Open DentAI is an AI-powered dental assistant app that simplifies dental practice management. It features voice recording for notes and gum depth readings, personalizes patient profiles, and offers Siri-like conversational interactions, enhancing efficiency and personalization in dental care.",
    features: ["Allows for recording notes and gum depth readings directly into the app.", "Customizes information for each patient.", "Provides a natural, conversational interface for user interaction."],
    benefits: ["Voice recording speeds up the process of data entry and management, reducing the time spent on administrative tasks.", "Personalized patient profiles help in tailoring dental care to individual patient needs, potentially improving patient satisfaction and care quality.", "The conversational AI interface makes the app easy to use, potentially reducing the learning curve and making daily tasks more manageable for dental professionals."]
  }
];

const Product: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      setCurrentIndex(prevIndex => (prevIndex === 0 ? products.length - 1 : prevIndex - 1));
    } else {
      setCurrentIndex(prevIndex => (prevIndex + 1) % products.length);
    }
  };

  return (
    <div className="carousel">
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {products.map((product, index) => (
          <div key={index} className="carousel-item">
            <div className="product-panel">
              <div className="product-info">
                <h2>{product.name}</h2>
                <p>{product.description}</p>
                <h4>Features:</h4>
                <ul className="features-list">
                  {product.features.map((feature, idx) => <li key={idx}>{feature}</li>)}
                </ul>
                <h4>Benefits:</h4>
                <ul className="benefits-list">
                  {product.benefits.map((benefit, idx) => <li key={idx}>{benefit}</li>)}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="carousel-controls">
        <button className="carousel-button" onClick={() => goToSlide('left')}>&#8249;</button>
        <button className="carousel-button" onClick={() => goToSlide('right')}>&#8250;</button>
      </div>
    </div>
  );
};

export default Product;