import React from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory
import '../styles/index.css';

const HomePage: React.FC = () => {
    const history = useHistory(); // Initialize useHistory

    const handleGetStartedClick = () => {
        history.push('/pricing'); // Navigate to the pricing page
    };

    return (
      <div className="home-container">
        {/* Hero Section */}
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="hero-headline">Discover SaaS apps on Openuse</h1>
            <p>Discover the power of SaaS with our suite of mobile applications designed to streamline your business operations. Whether you're looking to enhance productivity, secure your data, or scale effortlessly, our SaaS solutions are tailored to meet your needs.</p>
            <button className="hero-cta" onClick={handleGetStartedClick}>Get Started</button> {/* Add onClick handler */}
          </div>
        </div>
        {/* End of Hero Section */}
        <div className="services-grid">
        <div className="card">
            <img src="/images/Open-Storage.jpg" alt="Open Storage" className="card-icon" />
            <p><strong>Open Storage</strong> offers effortless control over your stock with a user-friendly interface, eliminating the need for manual tracking. Your data remains private and secure, stored only on your device. With mobile convenience, you can manage your inventory from anywhere, and the app's smart analytics provide valuable insights into sales trends and stock levels, helping you make informed decisions to optimize your business operations.</p>
            <a href="https://apps.apple.com/app/id6472116552" target="_blank" rel="noopener noreferrer">
              <img src="/images/AppStore.png" alt="Install iOS App" className="ios-install" />
            </a>
          </div>
          <div className="card">
            <img src="/images/Open-Nosh.jpg" alt="Open Nosh" className="card-icon" />
            <p><strong>Open Nosh</strong> is your smart solution for food inventory management, perfect for restaurants, caterers, and home kitchens. It features real-time tracking via barcode scanning, automatic restocking alerts, and expiry notifications to minimize waste. With detailed analytics, you can optimize costs and menu planning, all while enjoying a simple, secure interface that saves time and reduces stress.</p>
            <a href="https://apps.apple.com/app/id6477673232" target="_blank" rel="noopener noreferrer">
              <img src="/images/AppStore.png" alt="Install iOS App" className="ios-install" />
            </a>
          </div>
          <div className="card">
            <img src="/images/Open-Inspect.jpg" alt="Open Inspect" className="card-icon" />
            <p><strong>Open Inspect</strong> revolutionizes quality assurance with features like smartphone-based precise measurements, voice-controlled reporting, and automatic report generation. It offers real-time tolerance checks, offline data capture, and historical trend analysis, significantly reducing inspection time, eliminating errors, and enhancing product quality and workflow efficiency.</p>
            <a href="https://apps.apple.com/app/id6502964904" target="_blank" rel="noopener noreferrer">
              <img src="/images/AppStore.png" alt="Install iOS App" className="ios-install" />
            </a>
          </div>
          <div className="card">
            <img src="/images/Open-DentAI.jpg" alt="Open DentAI" className="card-icon" /> {/* Original image */}
            <p><strong>Open DentAI</strong> is an AI-powered dental assistant app that simplifies dental practice management. It features voice recording for notes and gum depth readings, personalizes patient profiles, and offers Siri-like conversational interactions, enhancing efficiency and personalization in dental care.</p>
            <p style={{ fontSize: '16px', color: 'green' }}>Coming soon</p> {/* Larger font size and green color */}
          </div>
        </div>
      </div>
    );
  };

export default HomePage;
