import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/pricingplan.css';

interface PricingPlan {
  name: string;
  price: string;
  description: string;
  cta: string; // Call to Action
  promotion: string;
  features: string[];
}

interface Product {
  name: string;
  plans: PricingPlan[];
}

const products: Product[] = [
  {
    name: 'Open Storage',
    plans: [
      {
        name: 'Basic',
        price: 'Free',
        description: 'Tailed for individual starters',
        cta: 'Get Started',
        promotion: 'Permanently Free',
        features: ['Secured data storage', 'Item intake via barcode scanning', 'Item intake via product name scanning', 'tem retrieval', 'Turnover alerts', 'Dashboard overview']
      },
      {
        name: 'Standard',
        price: '$9.99/month',
        description: 'Suited for small business',
        cta: 'Select Plan',
        promotion: 'Complimentary first month with an annual subscription',
        features: ['Encompassing all Basic features, plus:', 'Item retrieval via barcode scanning', 'Turnover push notifications', 'Reorder processing', 'Integration with wireless scanning devices', 'Dedicated mail support']
      },
      {
        name: 'Enterprise',
        price: 'Custom pricing',
        description: 'Optimized for teams or organizations',
        cta: 'Contact Us',
        promotion: 'Customized solutions designed to fit your business needs',
        features: ['Including all Standard features, with additional capabilities:', 'Multi-user access with role-based permissions', 'Secure iCloud storage (ensuring data integrity)', 'Collaborative inventory data sharing']
      }
    ]
  },
  {
    name: 'Open Nosh',
    plans: [
        {
            name: 'Basic',
            price: 'Free',
            description: 'Tailed for individual starters',
            cta: 'Get Started',
            promotion: 'Permanently Free',
            features: ['Secure Data Storage', 'Barcode Scanning for Receiving', 'Product Name Scanning for Receiving', 'Touch Counting', 'Stock Management', 'Expiration Alerts for Food Products', 'Dashboard Overview']
          },
          {
            name: 'Standard',
            price: '$19.99/month',
            description: 'Suited for small business',
            cta: 'Select Plan',
            promotion: 'Complimentary first month with an annual subscription',
            features: ['Encompassing all Basic features, plus:', 'Speech Recognition for Receivingt', 'Batch Check-In', 'Advanced Stock Reports for Insightful Analysis', ' Turnover Push Notifications', 'Restock Management', 'Integration with Wireless Scanning Devices', 'Dedicated Email Support']
          },
          {
            name: 'Enterprise',
            price: 'Custom pricing',
            description: 'Optimized for teams or organizations',
            cta: 'Contact Us',
            promotion: 'Customized solutions designed to fit your business needs',
            features: ['Including all Standard features, with additional capabilities:', 'Multi-user access with role-based permissions', 'Secure iCloud storage (ensuring data integrity) ', 'Collaborative inventory data sharing']
          }
    ]
  },
  {
    name: 'Open Inspect',
    plans: [
        {
            name: 'Basic',
            price: 'Free',
            description: 'Tailed for individual starters',
            cta: 'Get Started',
            promotion: 'Free trial',
            features: ['CSV Document Scanning', 'Voice Dictation', 'QA Report Genration', 'Dimension Editing', 'Drawings Management', 'Tolerance Validation']
          },
          {
            name: 'Standard',
            price: '$49.99/month',
            description: 'Suited for small business',
            cta: 'Select Plan',
            promotion: 'Complimentary first month with an annual subscription',
            features: ['Encompassing all Basic features, plus:', 'Scanning of Mechanical Engineering Drawings', 'Automatic "Balloon" Generation', 'QA Reports Sharing', 'Third-Party API Integration', 'Dedicated Email Support']
          },
          {
            name: 'Enterprise',
            price: 'Custom pricing',
            description: 'Optimized for teams or organizations ',
            cta: 'Contact Us',
            promotion: 'Tailored solutions for your business',
            features: ['Including all Standard features, with additional capabilities:', 'Multi-user access with role-based permissions', 'Secure iCloud storage (ensuring data integrity)', 'Collaborative QA data sharing']
          }
    ]
  },
  {
    name: 'Open DentAI',
    plans: [
        {
            name: 'Basic',
            price: 'Free',
            description: 'Tailed for individual starters',
            cta: 'Get Started',
            promotion: 'Free trial',
            features: ['Voice Dictation for Notes', 'Voice Dictation for Gum Depth Measurements', 'Customizable Patient Profiles']
          },
          {
            name: 'Standard',
            price: '$19.99/month',
            description: 'Suited for small business',
            cta: 'Select Plan',
            promotion: 'Complimentary first month with an annual subscription ',
            features: ['Siri-like Conversational Interface', 'Voice-Guided Procedure Instructions', 'Automatic Comparison of Patient History', 'Integration with Third-Party APIs', 'Dedicated Email Support']
          },
          {
            name: 'Enterprise',
            price: 'Custom pricing',
            description: 'Optimized for teams or organizations',
            cta: 'Contact Us',
            promotion: 'Tailored solutions for your business',
            features: ['Including all Standard features, with additional capabilities:', 'Multi-user access with role-based permissions', 'Secure iCloud storage (ensuring data integrity)', 'Collaborative QA data sharing']
          }
    ]
  }
];

const getStartedUrls: { [key: string]: string } = {
  'Open Storage': 'https://apps.apple.com/app/id6472116552',
  'Open Nosh': 'https://apps.apple.com/app/id6477673232',
  'Open Inspect': 'https://apps.apple.com/app/id6502964904',
  'Open DentAI': 'https://apps.apple.com/app/id0123456789'
};

const selectPlanUrls: { [key: string]: string } = {
  'Open Storage': 'https://apps.apple.com/app/id6472116552',
  'Open Nosh': 'https://apps.apple.com/app/id6477673232',
  'Open Inspect': 'https://apps.apple.com/app/id6502964904',
  'Open DentAI': 'https://apps.apple.com/app/id0123456789'
};

const PricingPlan: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<Product>(products[0]);

  const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProductName = event.target.value;
    const product = products.find(p => p.name === selectedProductName);
    if (product) {
      setSelectedProduct(product);
    }
  };

  return (
    <div className="pricing-page">
      <h1>Choose Your Favorite Plan</h1> {/* Added title */}
      <div>
        <label htmlFor="product-selector" className="product-selector-label">Select Product:</label> {/* Added class */}
      </div>
      <select id="product-selector" className="product-selector" onChange={handleProductChange}>
        {products.map((product, index) => (
          <option key={index} value={product.name}>{product.name}</option>
        ))}
      </select>
      <div className="pricing-plans">
        {selectedProduct.plans.map((plan, index) => (
          <div key={index} className="plan">
            <h2>{plan.name}</h2>
            <h3>{plan.price}</h3>
            <p>{plan.description}</p>
            <ul className="features">
              {plan.features.map((feature, idx) => (
                <li key={idx}>
                  <span className="check-mark">✓</span> {feature}
                </li>
              ))}
            </ul>
            <p className="promotion">{plan.promotion}</p>
            <button className="cta">
              {plan.cta === 'Contact Us' ? (
                <Link to="/contact" className="cta-link" style={{ textDecoration: 'none', color: 'inherit' }}>
                  {plan.cta}
                </Link>
              ) : plan.cta === 'Get Started' ? (
                <a href={getStartedUrls[selectedProduct.name]} className="cta-link" style={{ textDecoration: 'none', color: 'inherit' }} target="_blank" rel="noopener noreferrer">
                  {plan.cta}
                </a>
              ) : plan.cta === 'Select Plan' ? (
                <a href={selectPlanUrls[selectedProduct.name]} className="cta-link" style={{ textDecoration: 'none', color: 'inherit' }} target="_blank" rel="noopener noreferrer">
                  {plan.cta}
                </a>
              ) : (
                plan.cta
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlan;