import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/terms-of-use">Terms of Use</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <p>&copy; {new Date().getFullYear()} Stargrid Technologies, Inc. All rights reserved.</p>
      <p>San Mateo, California, USA</p> {/* Added address */}
    </footer>
  );
};

export default Footer;