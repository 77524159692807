import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/index.css';

interface MobileMenuProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
    handleLinkClick: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, toggleMenu, handleLinkClick }) => {
    const menuRef = useRef<HTMLDivElement>(null);

    const handleMenuToggle = useCallback(() => {
        toggleMenu();
    }, [toggleMenu]);

    const handleLinkClickCallback = useCallback(() => {
        handleLinkClick();
    }, [handleLinkClick]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            toggleMenu();
        }
    }, [toggleMenu]);

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen, handleClickOutside]);

    return (
        <div className="mobile-menu" ref={menuRef}>
            <button onClick={handleMenuToggle} className="menu-toggle">
                <span></span>
                <span></span>
                <span></span>
            </button>
            {isMenuOpen && (
                <nav className="mobile-nav">
                    <ul>
                        <li><Link to="/" onClick={handleLinkClickCallback}>Home</Link></li>
                        <li><Link to="/product" onClick={handleLinkClickCallback}>Products</Link></li>
                        <li><Link to="/pricing" onClick={handleLinkClickCallback}>Pricing</Link></li>
                        <li><Link to="/contact" onClick={handleLinkClickCallback}>Contact</Link></li>
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default React.memo(MobileMenu);
